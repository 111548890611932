<template>
   <Userheader></Userheader>
   <!-- 提现 -->
   <div class="row bg-white my-2 mx-0 py-2">
      <div>
         <p class="mb-0 text-danger">￥ {{ data.success_withdrawal }}</p>
         <p class="mb-0">已成功提现(元)</p>
      </div>
      <div class="border-top mt-2 py-2 d-flex align-items-center">
         <div class="me-auto">
            <p class="mb-0 text-danger">￥ {{ data.sure_withdrawal }}</p>
            <p class="mb-0">可提现(元)</p>
         </div>
         <div>
            <router-link class="btn btn-sm btn-outline-success" to="/team/withdrawal">提 现</router-link>
         </div>
      </div>
   </div>
   <!-- table -->
   <Table :data="data"></Table>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Userheader from '@/components/user/Header.vue'
import Table from '@/components/distribution/team/table'

const data = ref({
    sure_withdrawal: 10000.88, //账户余额
    success_withdrawal: 8888.88, //已成功提现金额
    commission: 18888.88, //总佣金
    commission_count: 100, //佣金明细
    withdrawal_record: 100, //提现记录
    team_peoples: 2000 //团队人数
})
</script>

<style>

</style>